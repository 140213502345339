import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import { isAuthenticated } from "./helpers/auth";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base:
    process.env.NODE_ENV === "development"
      ? process.env.BASE_URL + "promoters"
      : process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { allowAnonymous: true }
    },
    {
      path: "/security",
      name: "security",
      component: () =>
        import(/* webpackChunkName: "security" */ "@/views/Security.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/planner-features",
      name: "planner-features",
      component: () =>
        import(
          /* webpackChunkName: "planner-features" */ "@/views/PlannerFeatures.vue"
        ),
      meta: { allowAnonymous: true }
    },
    {
      path: "/buyer-features",
      name: "buyer-features",
      component: () =>
        import(
          /* webpackChunkName: "buyer-features" */ "@/views/BuyerFeatures.vue"
        ),
      meta: { allowAnonymous: true }
    },
    {
      path: "/how-it-works",
      name: "how-it-works",
      component: () =>
        import(/* webpackChunkName: "how-it-works" */ "@/views/HowItWorks.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/blog",
      name: "blog",
      component: () =>
        import(/* webpackChunkName: "blog" */ "@/views/Blog.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/about-us",
      name: "about-us",
      component: () =>
        import(/* webpackChunkName: "about-us" */ "@/views/About.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/faq",
      name: "faq",
      component: () =>
        import(/* webpackChunkName: "help-center" */ "@/views/FAQ.vue"),
      meta: {
        allowAnonymous: true
      }
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: () =>
        import(/* webpackChunkName: "contact-us" */ "@/views/Contact.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/terms",
      name: "terms",
      component: () =>
        import(/* webpackChunkName: "terms" */ "@/views/Terms.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () =>
        import(/* webpackChunkName: "privacy-policy" */ "@/views/Privacy.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/refunds",
      name: "refunds",
      component: () =>
        import(/* webpackChunkName: "refunds" */ "@/views/Refunds.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/cookies",
      name: "cookies",
      component: () =>
        import(/* webpackChunkName: "cookies" */ "@/views/Cookies.vue"),
      meta: { allowAnonymous: true }
    },
    {
      path: "/accessibility-statement",
      name: "accessibility-statement",
      component: () =>
        import(
          /* webpackChunkName: "accessibility-statement" */ "@/views/AccessibilityStatement.vue"
        ),
      meta: { allowAnonymous: true }
    },
    {
      path: "/dashboard",
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "./views/Dashboard.vue"),
      children: [
        {
          path: "",
          name: "dashboard",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/Dashboard/CurrentEvents.vue"
            )
        },
        {
          path: "past",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/Dashboard/PastEvents.vue"
            )
        }
      ]
    },
    {
      path: "/profile",
      name: "profile",
      component: () =>
        import(/* webpackChunkName: "profile" */ "@/views/Profile.vue")
    },
    {
      path: "/my-events",
      name: "my-events",
      redirect: "/dashboard"
    },
    {
      path: "/financials/:url",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "event-financials" */ "./views/Financials.vue"
        ),
      children: [
        {
          path: "",
          name: "event-financials",
          redirect: "commissions"
        },
        {
          path: "commissions",
          component: () =>
            import(
              /* webpackChunkName: "financials" */ "./views/Financials/Commissions.vue"
            )
        },
        {
          path: "transactions",
          component: () =>
            import(
              /* webpackChunkName: "financials" */ "./views/Financials/Transactions.vue"
            )
        }
      ]
    },
    {
      path: "/event-buyers-list/:event",
      name: "event-buyers-list",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "event-buyers-list" */ "./views/EventTicketsList.vue"
        )
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () =>
        import(
          /* webpackChunkName: "notifications" */ "@/views/Notifications.vue"
        )
    },
    {
      path: "/verify",
      name: "verify",
      meta: { allowAnonymous: true },
      component: () =>
        import(/* webpackChunkName: "verify" */ "@/views/Verify.vue")
    },
    {
      path: "/sale-list/:event",
      name: "sale-list",
      component: () =>
          import(
              "./views/SaleLists.vue"
              )
    },
    {
      path: "*",
      name: "not-found",
      component: () =>
        import(/* webpackChunkName: "404" */ "@/views/NotFound.vue")
    }
  ],

  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (!to.meta.allowAnonymous && !isAuthenticated()) {
    next({
      path: "/",
      query: { redirect: to.fullPath }
    });
  } else next();
});

router.afterEach((to, from) => {
  const toParts = to.path.split("/");
  const fromParts = from.path.split("/");

  // this handles page with child paths, not 100% sure this is fool proof
  if (
    toParts.length > 1 &&
    fromParts.length > 1 &&
    toParts[1] === fromParts[1]
  ) {
    return;
  }

  // reset navbar meta when navigating
  store.commit("setTitle", "");
  store.commit("setSubtitle", "");
  store.commit("setHelpVideoSrc", "");
});

export default router;
