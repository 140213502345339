import Vue from "vue";
import Vuex from "vuex";
import { getSystemTheme, setThemeCookie } from "../../shared/helpers/theme";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currency: {
      name: "CAD",
      value: 1
    },
    helpVideoSrc: null,
    site: "promoters",
    session_id: null,
    theme: "dark",
    user: { isAuthenticated: false },
    count: 0,
    loading: false,
    authenticated: false,
    bus: new Vue(),
    title: '',
    subtitle: ''
  },
  mutations: {
    setHelpVideoSrc(state, payload) {
      state.helpVideoSrc = payload;
    },
    setCurrency(state, payload) {
      state.currency = payload;
    },
    setTheme(state, theme) {
      if (theme) {
        if (theme === "system") {
          state.theme = getSystemTheme();
        } else {
          state.theme = theme;
        }

        setThemeCookie(theme);
      }
    },
    updateProfileImg: (state, data) => {
      state.user.profile_img = data;
    },
    setUser(state, data) {
      state.user = data;
      state.user.isAuthenticated = true;
      state.user.name = data.first_name + " " + data.last_name;
      state.user.upcomingTickets = data.upcomingTickets;
    },
    updateUserProperty: (state, obj) => {
      state.user[obj.property] = obj.value;
    },
    setData(state, data) {
      state.session_id = data.session_id;
      state.user.activated = data.activated;
      state.profileComplete = data.profileComplete;
      state.user.coordinator = data.coordinator;
      state.profileComplete = data.profileComplete;
    },
    //TODO improve logout
    setLogout(state, data) {
      state.user = { isAuthenticated: false };
      state.session_id = null;
      if ($cookies.isKey("currency")) {
        $cookies.remove("currency");
      }
    },
    setUserName(state, data) {
      state.user = {
        ...state.user,
        first_name: data.firstName,
        last_name: data.lastName,
        name: data.firstName + " " + data.lastName
      };
    },
    setTitle(state, title) {
      state.title = title;
    },
    setSubtitle(state, subtitle) {
      state.subtitle = subtitle
    }
  },
  actions: {
    updateHelpVideoSrc(state, payload) {
      state.commit("setHelpVideoSrc", payload);
    },
    updateCurrency(state, payload) {
      state.commit("setCurrency", payload);
    },
    logout({ commit }) {
      commit("setLogout", null);
    },
    toggleTheme({ commit, state }) {
      commit("setTheme", state.theme === "dark" ? "light" : "dark");
    },
  },
  getters: {
    getIsAuth: state => {
      return state.user.isAuthenticated;
    }
  }
});
